<template>

  <v-badge
      :content="qty + ''"
      bottom
      class="pa-0 px-0 ma-1 mx-0 qt-badge"
      color="#FF6969"
      style="min-width: 100% !important; max-width: 100% !important;"
  >

    <button v-if="this.item.productImage && this.item.productImage.length > 7" :id="'item_' + this.item.id" class="pa-0"
            style="min-width: 100%; max-width: 100% !important;">
      <v-card


          :color="backgroundColor"
          class="mx-auto pa-1"
          elevation="1"
          max-width="100%"
          width="100%"
          @click="selectItem"
      >
        <!--        <v-img
                    :src="this.imageUrl"
                    class="white&#45;&#45;text align-end"
                    style="height: 50px"
                    width="100%"
                />-->

        <v-card-subtitle :style="{
          color: (checkColorLightOrDark(backgroundColor) === 'light' ? 'black' : 'white'),
          'font-size': (settings.settings.layoutSettings_mobileFontSize > 0) ? `${settings.settings.layoutSettings_mobileFontSize}px !important` : `12px !important`
        }"
                         class="pa-0 item_name mx-0 mb-4 pt-2 pl-0 text-left">
          {{
            (settings.settings.layoutSettings_mobileItemNameLength > 0) ? itemName.substring(0, settings.settings.layoutSettings_mobileItemNameLength) : itemName
          }}
        </v-card-subtitle>
        <strong :style="{
          color: $vuetify.theme.dark? 'black' : 'white'
        }" :class="(checkColorLightOrDark(backgroundColor) === 'light' ? 'text-black' : 'text-light')"
                class="mx-auto font-weight-bolder pl-0 text-left"
                style="margin-left: -27px !important;">
          {{ item.sellPrice | currency }}
        </strong>
        <BookItem ref="bookItem" :pos-type="posType"></BookItem>
      </v-card>
    </button>


    <button v-else :id="'item_' + this.item.id"
            class="pa-0" style="min-width: 100%; max-width: 100% !important;">
      <v-card

          :color="backgroundColor"
          class="mx-auto pa-1"
          elevation="1"
          max-width="100%"
          @click="selectItem"
      >
        <!--        <v-row
                    align="center"
                    class="row mx-0 white&#45;&#45;text"
                    style="height: 50px;"
                    width="100%"
                >
                  <h1 :class="(checkColorLightOrDark(backgroundColor) === 'light' ? 'text-muted' : 'text-light')"
                      class="mx-auto">
                    {{ itemName.substring(0, 1) }}
                  </h1>
                </v-row>-->


        <v-card-subtitle :style="{
          color: (checkColorLightOrDark(backgroundColor) === 'light' ? 'black' : 'white'),
          'font-size': (settings.settings.layoutSettings_mobileFontSize > 0) ? `${settings.settings.layoutSettings_mobileFontSize}px !important` : `12px !important`
        }"
                         class="pa-0 item_name mx-0 mb-4 pt-2 text-justify pl-0">
          {{
            (settings.settings.layoutSettings_mobileItemNameLength > 0) ? itemName.substring(0, settings.settings.layoutSettings_mobileItemNameLength) : itemName
          }}
        </v-card-subtitle>
        <span :style="{
          color: $vuetify.theme.dark? 'black' : 'white'
        }" :class="(checkColorLightOrDark(backgroundColor) === 'light' ? 'text-black' : 'text-light')"
              class="mx-auto font-weight-bolder pl-0 text-left"
              style="margin-left: -18px !important;">
      {{ item.sellPrice | currency }}
    </span>

        <BookItem ref="bookItem" :pos-type="posType"></BookItem>
      </v-card>
    </button>


  </v-badge>


</template>

<style scoped>
.itemPrice {
  font-family: Anton;
  font-weight: normal;
  letter-spacing: 2px;
}

.v-responsive__sizer {
  padding-bottom: 0% !important;
}

@media (max-width: 1024px) {
  .itemName {
    font-size: 14px;
  }

  .itemPrice {
    font-size: 12px;
  }
}

@media (min-width: 1025px) {
  .itemName {
    font-size: 19px;
  }

  .itemPrice {
    font-size: 14px;
  }
}
</style>

<script>
const navig = navigator;
import BookItem from "@/mixins/pos/BookItem";
import {checkColorLightOrDark} from "@/plugins/checkColorLightOrDark";
import {mapGetters, mapState} from "vuex";

export default {
  name: "MobileItem",

  components: {
    BookItem
  },

  props: {
    posType: String,
    item: Object,
    gridHeight: Number,
    gridWidth: Number,
  },
  data() {
    return {
      imageUrl: "",
      qty: 0
    }
  },
  watch: {},
  computed: {
    ...mapState([
      'api',
      'pos',
      'settings'
    ]),
    ...mapGetters('pos/gastro', {
      gastroOpenItems: 'openItems',
      gastroOrderedItems: 'orderedItems',
      party: 'party',
    }),
    openItems() {
      if (this.posType === "gastro") {
        return this.gastroOpenItems;
      }

      return [];
    },
    itemName() {
      if (this.posType === "retail") {
        if (this.item.isVariation) {
          return this.item.name + " " + this.item.variationSize + " " + this.item.variationColor;
        }
      }

      return this.item.name;
    },
    backgroundColor() {
      if (this.item.backgroundColor === "" || this.$vuetify.theme.dark) {
        return "#FFFFFF";
      }

      return this.item.backgroundColor;
    },

  },
  methods: {
    checkColorLightOrDark,
    getItem() {
      if (this.posType === "gastro") {
        this.$store.dispatch("pos/gastro/selectInvoiceOrderedItem", this.item);
      } else if (this.posType === "retail") {
        this.$store.dispatch("pos/retail/selectInvoiceItem", this.item);
      }
    },
    selectItem() {
      this.$refs.bookItem.selectItemEAN(this.item.ean);
    }
  },
  mounted() {

    this.imageUrl = "https://img.3pos.de/unsafe/" + Math.round(this.gridWidth) + "x" + Math.round(this.gridHeight) + "/billsys_" + this.api.auth.posID + "/original/" + this.item.productImage;
  }
}
</script>


<style scoped>

button:active {
  box-shadow: 0px 4px 8px #FF6969 !important;;
  -webkit-transform: scale(0.98) !important;;
  transform: scale(0.98) !important;
}

.item_name {
  height: 60px !important;
}
</style>
<style>


@media (max-width: 600px) {
  .qt-badge .v-badge__badge {
    border: 1px solid white !important;
    padding-top: 3px !important;
    min-width: 10px !important;
    min-height: 10px !important;
    left: calc(100% - 22px) !important;
    bottom: calc(100% - 15px) !important;
    top: calc(100% - 22px) !important;
  }
}

@media (min-width: 601px) {
  .qt-badge .v-badge__badge {
    border: 1px solid white !important;
    padding-top: 5px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    left: calc(100% - 48px) !important;
    bottom: calc(100% - 20px) !important;
    top: calc(100% - 27px) !important;
  }
}
</style>
