<template>

  <div>
    <v-menu
        absolute
        auto
        class="mx-auto ma-auto mr-100 mt-10"
        max-height="100%"
        min-width="200px"
        offset-y
        transition="scale-transition"
    >

      <template v-slot:activator="{ on, attrs }">
        <button v-bind="attrs" v-on="on" class="btn-center-action elevation-20" @click="checkTable">
          <div class="btn__content">
            <v-badge v-if="pos.gastro.party && pos.gastro.party.name && pos.gastro.party.name.length > 0"
                     color="error"
                     :content="parties.length"
            >
              <span class="text-wrap" style="font-size: 11px !important;">
            {{ pos.gastro.party.name }}
          </span>
            </v-badge>
            <font-awesome-icon v-else :icon="['fal', 'concierge-bell']" class="mb-1" style="font-size: 25px;"/>
          </div>
        </button>
      </template>
      <v-list v-if="this.pos.gastro.table !== null" class="pt-0 pb-0">
        <v-list-item
            v-if="this.pos.gastro.table.parties && this.pos.gastro.table.parties.length > 0 && this.pos.gastro.table.maxParties > this.pos.gastro.table.parties.length"
            class="pt-0 pb-0 border-bottom"
            @click="showPartyAddDialog=true">
          <v-list-item-content>
            <v-list-item-title>
              {{ this.$t('generic.lang_createParty') }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon color="#FF6969">
              mdi-plus
            </v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
            v-for="(party , index) in this.pos.gastro.table.parties"
            :key="index"
            class="pt-0 pb-0 border-bottom"
            @click="changeParty(party)"
        >
          <v-list-item-title>{{ party.name }}</v-list-item-title>
          <v-list-item-action class="pa-0 ma-0">
            <v-btn class="pa-0" elevation="0" fab small>
              <v-icon small>
                chevron_right
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

    </v-menu>
    <parties-add-dialog :pos-type="posType" :show-dialog="showPartyAddDialog"
                        @closeDialog="closeCreatePartyDialog"/>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faConciergeBell} from '@fortawesome/pro-light-svg-icons'
import PartiesAddDialog from "@/components/pos/PartiesAddDialog";

library.add(faConciergeBell);

export default {
  name: "PartiesButton",
  props: {
    posType: {
      type: String,
      required: true
    }
  },
  components: {
    PartiesAddDialog,
    'font-awesome-icon': FontAwesomeIcon
  },
  computed: {
    ...mapGetters('pos/gastro', {
      gastroOpenItems: 'openItems',
      gastroOrderedItems: 'orderedItems',
      gastroTableName: 'tableName'
    }),
    ...mapState([
      'pos',
      'user'
    ]),
    parties() {
      if (this.pos.gastro.table === null) {
        return [];
      }
      return this.pos.gastro.table.parties;
    }
  },
  data: () => {
    return {
      showPartyAddDialog: false
    }
  },

  methods: {
    checkTable() {
      if (this.pos.gastro.table === null)
        this.$bvToast.toast(this.user.name + ", " + this.$t('generic.lang_pleaseSelectTable'), {
          title: this.$t('generic.lang_noTableSelected'),
          autoHideDelay: 4000,
          variant: 'danger'
        })

    },
    closeCreatePartyDialog(partyName) {
      this.showPartyAddDialog = false;

      if (partyName.length > 0) {
        this.$nextTick().then(() => {
          this.changeParty(
              {
                name: partyName
              }
          );
        });
      } else {
        //this.changeParty(partyName);
      }
    },
    changeParty(party) {
      this.$store.dispatch("pos/gastro/changeParty", {
        posType: this.posType,
        party: party
      });
    },
  }
}
</script>

<style scoped>

/* nav */
.btn-center-action {
  color: white;
  border: 1px solid white;
  box-sizing: content-box;
  position: absolute;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none;
  user-select: none;
  outline: 0;
  width: 56px;
  height: 56px;
  background-color: #FF6969;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: -1px 9px 300px -38px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: -1px 9px 300px -38px rgba(0, 0, 0, 0.75) !important;
  box-shadow: -1px 9px 300px -38px rgba(0, 0, 0, 0.75) !important;
}

.btn-center-action:hover {
  cursor: pointer;
}

.btn__content {
  display: flex;
  align-items: center;
  border-radius: inherit;
  color: inherit;
  flex: 1 1 auto;
  justify-content: center;
  position: relative;
  font-size: 20px;
  white-space: nowrap;
  width: inherit;
}

a {
  text-decoration: none !important;
  outline: none !important;
}

.v-list-item--link:before {
  text-decoration: none !important;
  background: none !important;
}
</style>
