<template>
  <div>
    <v-list-item
        v-touch="{
      left: () => selectItemSwiper('left'),
      right: () => selectItemSwiper('right'),
    }"
        class="border-bottom pl-1"
        style="height: 58px"
        v-bind:style="{
    'background-color': (item === this.selectedItem ? 'rgba(63,106,216,0.2)' : (itemIndex % 2 === 1 ? '' : ''))
  }"

    >

      <v-list-item-avatar id="qty_icon" :color="this.colors.primary" tile @click="reBookItem">
        <font-awesome-icon :icon="['fal', 'plus']" class="text-grey"
                           style="font-size: 18px; color: white !important;"/>
      </v-list-item-avatar>
      <v-list-item-content class="ml-2" @click="selectItem">
        <v-list-item-title
            :style="{'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through': '')}"
            class="font-weight-bolder text-muted"
            v-text="this.itemName"></v-list-item-title>
        <v-list-item-subtitle>
          {{ item.id }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-icon class="ma-0 pa-0"
      >

        <button v-if="item.freeText !== null" :disabled="item.isVoid" class="text-light pl-0 pr-0 ma-auto mx-auto"
                style="width: 30px !important; height: 58px; background-color: #21D59B;  margin-right: 2px !important;"
        >
          <font-awesome-icon :icon="['fal', 'comment-alt-lines']" class="text-light font-size-xlg"
          />
        </button>
        <button v-if="item.course" :disabled="item.isVoid"
                class="text-light font-size-xlg pl-0 pr-0 mr-1 ma-auto mx-auto"
                style="width: 30px !important; height: 58px;  margin-right: 2px !important;" v-bind:style="{
        'background-color' : this.colors.primary
      }"
                @click="openCourseDialog"
        >
          {{ item.course.position }}
        </button>

        <button v-if="item.selectedExtras&&item.selectedExtras.length > 0" :disabled="item.isVoid"
                class="text-light bg-warning pl-0 pr-0 ma-auto mx-auto "
                style="width: 30px !important; height: 58px">
          <font-awesome-icon :icon="['fal', 'pepper-hot']" class="text-light font-size-xlg"
          />
        </button>
      </v-list-item-icon>

      <v-list-item-action class="text-center px-0 pa-0 pl-0 ml-2" style="max-width: 80px !important;">
        <v-row align="center" class="ma-0 mx-0" no-gutters>
          <v-col :style="{'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through': '')}"
                 class="text-center"
                 cols="12" md="12" sm="12">
            <strong class="" v-bind:style="{
        color: colors.primary
      }">{{ this.itemSellPrice | currency }}</strong> <strong v-if="this.item.discount > 0"
                                                              class="success--text">({{ this.item.discount }}%)</strong>
          </v-col>
          <v-col :style="{'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through': '')}"
                 class="text-center"
                 cols="12" md="12" sm="12">
          <span class="text-muted mx-auto text-center ma-auto">
           x {{ item.amount }}
          </span>
          </v-col>
        </v-row>
      </v-list-item-action>
      <BookItem ref="bookItem" :pos-type="posType"></BookItem>
      <mobile-course-dialog :pos-type="this.posType" :show-dialog="showCourseDialog" @closeDialog="closeDialog"/>
      <ButtonsFreetextDialog :item="selectedItem" :pos-type="posType" :show-dialog="showDialog"
                             @closeDialog="showDialog = false"/>
    </v-list-item>
    <template
        v-if="selectedItem!==null && item.selectedExtras.length > 0&&selectedItem.randomPositionID===item.randomPositionID">
      <v-list-item class="pa-0"
                   :style="{minHeight:'30px', 'background-color': (item === selectedItem ? 'rgba(63,106,216,0.2)' : (itemIndex % 2 === 1 ? '#ededed' : ''))}"
      >
        <v-list-item-content class="pa-0">
          <v-row justify="start" align-self="start" class="ma-0 mx-0" no-gutters>
            <v-col cols="12">
              <div v-if="true">
                <div class="text-wrap" style="font-size: 12px " v-for="(extra,i) in item.selectedExtras"
                     :key="extra.name+i">
                  &#9656; {{ extra.amount }}x {{ extra.name }} {{ extra.sellPrice | currency }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import ButtonsFreetextDialog from "@/components/pos/buttons/ButtonsFreetextDialog";
import {roundNumber} from "@/plugins/round";
import {format} from 'mathjs';

import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faChevronLeft,
  faCommentAltLines,
  faExchange,
  faMinus,
  faPlus,
  faShoppingBag,
  faShoppingCart
} from '@fortawesome/pro-light-svg-icons'

import {library} from '@fortawesome/fontawesome-svg-core';
import MobileCourseDialog from "@/components/pos/mobile/wedgets/MobileCourseDialog";
import BookItem from "@/mixins/pos/BookItem";

library.add(
    faChevronLeft, faAngleDoubleUp, faAngleDoubleDown, faShoppingBag, faShoppingCart, faExchange, faPlus, faMinus, faCommentAltLines
);
export default {
  name: "MobileInvoiceOrderedItem",
  components: {
    BookItem,
    'font-awesome-icon': FontAwesomeIcon,
    MobileCourseDialog,
    ButtonsFreetextDialog
  },
  data: () => {
    return {
      showCourseDialog: false,
      showDialog: false
    }
  },
  props: {
    posType: String,
    item: Object,
    itemIndex: Number,
    colors: {
      type: Object,
      require: true
    }
  },
  methods: {
    reBookItem() {
      this.$refs.bookItem.selectItemEAN(this.item.ean);
    },
    show_freeText_dialog() {
      if (this.posType === "gastro" && this.item !== this.pos.gastro.selectedOrderedItem) {
        this.$store.dispatch("pos/gastro/selectInvoiceOrderedItem", this.item);
      } else if (this.posType === "retail" && this.item !== this.pos.gastro.selectedOrderedItem) {
        this.$store.dispatch("pos/retail/selectInvoiceItem", this.item);
      }
      this.showDialog = true;
    },
    openCourseDialog() {
      if (this.posType === "gastro" && this.item !== this.pos.gastro.selectedOrderedItem) {
        this.$store.dispatch("pos/gastro/selectInvoiceOrderedItem", this.item);
      } else if (this.posType === "retail" && this.item !== this.pos.gastro.selectedOrderedItem) {
        this.$store.dispatch("pos/retail/selectInvoiceItem", this.item);
      }
      this.showCourseDialog = true;
    },
    closeDialog() {
      this.showCourseDialog = false;
    },
    selectItemSwiper(direction = '') {
      if (direction === 'right')
        this.$emit('closeLeftReveal', this.item)
      else if (direction === 'left')
        this.$emit('closeRightReveal', this.item)

      if (this.posType === "gastro" && this.item !== this.pos.gastro.selectedOrderedItem) {
        this.$store.dispatch("pos/gastro/selectInvoiceOrderedItem", this.item);
      } else if (this.posType === "retail" && this.item !== this.pos.gastro.selectedOrderedItem) {
        this.$store.dispatch("pos/retail/selectInvoiceItem", this.item);
      }
    },
    selectItem() {
      if (this.posType === "gastro" && this.item) {
        this.$store.dispatch("pos/gastro/selectInvoiceOrderedItem", this.item);
      } else if (this.posType === "retail") {
        this.$store.dispatch("pos/retail/selectInvoiceItem", this.item);
      }
    }
  },
  computed: {
    ...mapState([
      'pos',
      'mealsizes',
      'deposit'
    ]),
    selectedItem() {
      if (this.posType === "retail") {
        return this.pos.retail.selectedItem;
      }

      if (this.posType === "gastro") {
        return this.pos.gastro.selectedOrderedItem;
      }

      return null;
    },
    depositGroup() {
      if (this.item.depositgroupID === 0)
        return null;

      //FIND DEPOSIT GROUP
      let depositGroup = this.deposit.deposits.find((depositGroup) => {
        return depositGroup.id === this.item.depositgroupID;
      });

      if (!depositGroup)
        return null;

      return depositGroup;
    },
    itemName() {
      if (this.posType === "retail") {
        if (this.item.isVariation) {
          return this.item.name + " " + this.item.variationSize + " " + this.item.variationColor;
        }
      }

      //CHECK IF ITEM HAS MEALSIZE ID
      if (this.item.mealSizeID > 0) {
        //FIND MEALSIZE
        let mealsize = this.mealsizes.mealSizes.find((mealsize) => mealsize.id === this.item.mealSizeID);

        if (mealsize)
          return this.item.name + " " + mealsize.name;
      }

      return this.item.name;
    },
    itemSellPrice() {
      let sellPrice = this.item.sellPrice;

      // -----------------------------------------------------
      // --------------------- DISCOUNT -----------------------
      //Discount ONLY IN %
      if (this.item.sellPrice === this.item.originalSellPrice && this.item.discount > 0) {
        //sellPrice = ((100 - this.item.discount) / 100) * sellPrice;
        //sellPrice = parseFloat((Math.round(((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
        sellPrice = parseFloat(((((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100));
        sellPrice = format(sellPrice, {precision: 14});
        sellPrice = roundNumber(sellPrice, 2);
      }

      //DISCOUNT WITH PRICE CHANGE
      if (this.item.sellPrice !== this.item.originalSellPrice && this.item.discount === 0) {
        sellPrice = 1 * sellPrice;
      }

      //DISCOUNT IN % AND PRICECHANGE
      if (this.item.sellPrice !== this.item.originalSellPrice && this.item.discount > 0) {
        //sellPrice = 1 * (((100 - this.item.discount) / 100) * sellPrice)
        //sellPrice = parseFloat((Math.round(((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
        sellPrice = parseFloat(((((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100));
        sellPrice = format(sellPrice, {precision: 14});
        sellPrice = roundNumber(sellPrice, 2);
      }
      // -----------------------------------------------------

      //CHECK DEPOSIT
      if (this.depositGroup !== null) {
        sellPrice += this.depositGroup.priceTotal;
      }

      sellPrice = format(sellPrice, { precision: 14 });
      sellPrice = roundNumber(sellPrice, 2);
      return sellPrice;
    },
    itemStackSellPrice() {
      let weightCalc = 0.00;
      weightCalc = this.itemSellPrice * this.item.amount
      weightCalc = format(weightCalc, { precision: 14 });
      weightCalc = roundNumber(weightCalc, 2);
      return weightCalc;
    },
  }
}
</script>

<style scoped>
#qty_icon:hover, #qty_icon:active {
  color: #1F9BCF !important;
}
</style>
