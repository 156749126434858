<template>
  <v-dialog v-model="dialog" max-width="700" overlay-opacity="0" scrollable>
    <template v-slot:activator="{on}">
      <button v-on="on" class="btn-square btn-transition border-0 btn bg-info mx-0 ma-0"
              style="height: 59px !important;width: 59px !important;">
        <i class="btn-icon-wrapper">
          <font-awesome-icon :icon="['fal', 'info-square']" class="text-light" style="font-size: 28px"/>
        </i>
      </button>
    </template>

    <v-card class="rounded-lg">
      <v-card-text class="rounded-lg text-left pa-2">
        <div class="rounded-lg row mx-0 text-left ma-0">
          <ButtonsDiscount v-if="$store.getters['permissions/checkPermission']('allowGiveDiscount')" :pos-type="posType"
                           @click="dialog=false"/>

          <ButtonsItemInfo :pos-type="posType" @click="dialog=false"/>
<!--          <set-qty-button :item="this.item" :pos-type="posType" @click="dialog=false"/>-->
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import {library} from '@fortawesome/fontawesome-svg-core'
import {faInfoSquare} from '@fortawesome/pro-light-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import ButtonsDiscount from "@/components/pos/buttons/ButtonsDiscount";
import ButtonsItemInfo from "@/components/pos/buttons/ButtonsItemInfo";
import SetQtyButton from "@/components/pos/mobile/wedgets/buttons/SetQtyButton";

library.add(
    faInfoSquare
);
export default {
  name: "MoreOptionsButton",
  components: {
    SetQtyButton,
    'font-awesome-icon': FontAwesomeIcon,
    ButtonsDiscount,
    ButtonsItemInfo
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    posType: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      fab: false,
      dialog: false
    }
  }
}
</script>

<style scoped>

</style>

<style>
/* This is for documentation purposes and will not be needed in your application */

.v-btn--floating {
  position: relative !important;
  z-index: 4000;
}

.speed-dial-z-index {

}
</style>
