<template>
  <div class="flex pa-1" v-bind:class="this.$vuetify.breakpoint.smAndDown? 'pa-0 px-0 ma-0 mx-0' : ''"
       v-bind:style="{
    'width' : this.$vuetify.breakpoint.smAndDown? '42px !important' : ''
  }">

    <button v-if="this.$vuetify.breakpoint.smAndDown"
            class="btn-transition border-0 btn bg-dark"
            style="height: 40px !important;width: 40px !important;"
            @click="openDialog">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'sort-numeric-up-alt']" class="text-light" style="font-size: 25px"/>
      </i>
    </button>
    <button v-else class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-alternate"
            @click="openDialog">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'sort-numeric-up-alt']"/>
      </i><span v-if="showButtonText">{{ $t('generic.lang_note') }}</span>
    </button>

    <v-dialog v-model="showDialog" :persistent="qty.length === 0 || qty.length === undefined" max-width="600"
              scrollable>
      <v-card>
        <v-card-text class="pb-0 pt-5">
          <v-text-field v-model="qty" :min="1" dense outlined type="number"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'

import {faSortNumericUpAlt} from '@fortawesome/pro-light-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {mapState} from "vuex";


library.add(
    faSortNumericUpAlt
);
export default {
  name: "SetQtyButton",
  props: {
    item: {
      type: Object,
      required: true
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    posType: String,
    showButtonText: Boolean
  },
  components: {
    'font-awesome-icon': FontAwesomeIcon
  },
  data: () => {
    return {
      showDialog: false,
      qty: ""
    }
  },
  computed: {
    ...mapState([
      'pos'
    ]),
    selectedItem() {
      if (this.posType === "retail") {
        if (this.pos.retail.selectedItem !== null) {
          return this.pos.retail.selectedItem;
        }
      }

      if (this.posType === "gastro") {
        if (this.pos.gastro.selectedOpenItem !== null) {
          return this.pos.gastro.selectedOpenItem;
        } else if (this.pos.gastro.selectedOrderedItem !== null) {
          return this.pos.gastro.selectedOrderedItem;
        }
      }

      return null;
    }
  },
  watch: {
    qty(val) {
      if (this.item !== this.pos.gastro.selectedOpenItem)
        this.selectItem();

      ////console.log(val.length)
      if (val.length === 0 || val.length === undefined) {
        this.qty = 1
      }
      if (this.posType === "retail") {
        this.$store.dispatch("pos/retail/numpadQTY", val + "");
      }

      if (this.posType === "gastro") {
        this.$store.dispatch("pos/gastro/numpadQTY", val + "");
      }
    }
  },
  mounted() {
    this.qty = this.item.amount;
  },
  methods: {
    selectItem() {
      this.$store.dispatch("pos/gastro/selectInvoiceOpenItem", this.item);
    },
    openDialog() {
      if (this.selectedItem === null) {
        //if discount all
        //return;
      } else {
        if (this.selectedItem.isVoid)
          return;

        if (this.selectedItem.ean.includes("V$") || this.selectedItem.ean.includes("GF$") || this.selectedItem.ean.includes("ZMYLE$") || this.selectedItem.ean.includes("MYWORLD$"))
          return;
      }

      this.showDialog = true;
    }
  },
}
</script>

<style scoped>

</style>
