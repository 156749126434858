<template>
  <div class="pa-1">
    <!--loading order overlay-->
    <v-overlay
        absolute
        :value="this.loadingOrder||this.loading"
    >
      <v-card color="white" max-width="400" v-if="this.isConnectionError">
        <v-card-text class="warning--text">
          {{ $t('generic.lang_unableLoadOrder') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn depressed @click="redirect('tables')" color="primary">
            {{ $t('generic.lang_gastroPOS_gotoTableOverview') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-progress-circular
          indeterminate
          v-else
          color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-touch ref="swiper"
             @left-swipe="swipeHandler()"
    >
      <v-row id="posGrid" align="center" class="" no-gutters>
        <v-btn v-if="currentGridLevel > 1" id="back-btn" fab @click="goBack">
          <font-awesome-icon
              :icon="['fal', 'chevron-left']"
              class="itemgroupIcon mx-auto"

              style="color:#FF6969; font-size: 30px;  "/>
        </v-btn>
        <v-col v-for="(gridItem, index) in gridItems" :key="gridKey(index, gridItem)"
               class="mx-0 ma-auto pa-1"
               cols="3" sm="3" style="height: auto">
          <mobile-item-group v-if="gridItem.type === 'waregroup' || gridItem.type === 'waregroup_virtual'"
                             :itemgroup="gridItem.gridItem" @click.native="selectGridItem(gridItem,index)"/>
          <mobile-item v-if="gridItem.type === 'item'" :gridHeight="gridItemHeightProp" :gridWidth="gridItemWidthProp"
                       :item="gridItem.gridItem" :pos-type="posType"/>

          <v-card v-if="gridItem.type === 'empty_placeholder'" elevation="1" height="100%" width="100%"></v-card>
        </v-col>
      </v-row>
    </v-touch>

    <div id="items">
      <v-touch ref="bottom_swiper" style="z-index: 1000"
               @up-swipe="swipe_up()"
               @down-swipe="swipe_down()"
      >
        <v-app-bar height="72" id="gastro_bottom_nav" ref="gastro_bottom_nav" :elevation="/*this.is_up? '1' : '0'*/ '0'"
                   :fixed="this.is_up"
                   class="mx-0 pl-0 pr-0">


          <font-awesome-icon
              @click="swipe_down()"
              v-if="this.is_up"
              :icon="['fal', 'angle-double-down']"
              class="ml-1"
              style="color:green; font-size: 24px;  "
          />


          <font-awesome-icon
              @click="swipe_up()"
              v-if="!this.is_up"
              :icon="['fal', 'angle-double-up']"
              class="ml-1"
              style="color:white; font-size: 24px;  "
          />


          <v-row @click="is_up? swipe_down() : swipe_up()" align="center"
                 class="text-white ml-1 mt-1"
                 no-gutters
                 style="width: auto"
          >
            <v-col class="font-weight-bolder text-white pa-0 px-0 ma-0 mx-0"
                   cols="12">
              <h6 class="pa-0 px-0 ma-0 mx-0 font-size-sm">
                {{ is_up ? capitalize($t('generic.lang_close')) : capitalize($t('generic.lang_open')) }}
              </h6>
            </v-col>
            <v-col class="text-white pa-0" cols="12">
              <h5 class="pa-0 px-0 ma-0 mx-0 font-size-xlg"
                  style="font-family: Impact, Charcoal, sans-serif !important;">
                {{ (is_up ? total : parseFloat(totalOpenItems).toFixed(2)) | currency }}
                <!--                {{ parseFloat(totalOpenItems).toFixed(2)| currency }}-->
              </h5>
            </v-col>
          </v-row>

          <v-spacer/>
          <!--          <font-awesome-icon
                        v-if="!this.is_up"
                        :icon="['fal', 'paper-plane']"
                        class="mr-3"
                        style="color:white; font-size: 30px;  "
                    />-->
          <v-btn @click="plu_dialog = true" text color="white">
            PLU
          </v-btn>
          <v-spacer/>
          <v-row v-if="!this.is_up" align="center"
                 class="text-white ml-1 mt-1 text-right"
                 no-gutters
                 style="width: 100px !important"
          >
            <v-col class="font-weight-bolder text-white pa-0 px-0 ma-0 mx-0" cols="12">
              <h6 class="pa-0 px-0 ma-0 mx-0 font-size-sm">
                {{ this.$t('generic.lang_total') }}
              </h6>
            </v-col>
            <v-col class="text-white pa-0" cols="12">
              <h5 class="pa-0 px-0 ma-0 mx-0 font-size-xlg"
                  style="font-family: Impact, Charcoal, sans-serif !important;">
                {{ total | currency }}
              </h5>
            </v-col>
          </v-row>

          <footer-button-order :enabled="!this.is_up  && (showOrderButton || canOrder)" :pos-type="posType"
                               loader_color="white" @redirect="redirect"/>


          <footer-button-order :enabled="this.is_up && (showOrderButton || canOrder)" :id="this.is_up && (showOrderButton || canOrder)?'send_btn':''" :pos-type="posType"
                               class="mr-2"
                               loader_color="blue"
                               style="padding-top: 2.5px; padding-left: 2px" @redirect="redirect"/>

          <!--          <v-btn v-if="this.is_up" id="send_btn" class="mr-2 pl-3" color="white" outlined>
                      <font-awesome-icon

                          :icon="['fal', 'paper-plane']"

                          style="color:white; font-size: 33px;  "
                      />
                    </v-btn>-->


          <!--          <template v-if="this.is_up" v-slot:extension>
                      <v-tabs id="tabs" v-model="order_type"
                              active-class="actived_tab" color="white">
                        <v-tab>
                          Open
                        </v-tab>

                        <v-tab>
                          {{ $t('erp.lang_ordered') }}
                        </v-tab>
                      </v-tabs>

                      <v-spacer/>
                      <div style="width: 120px">
                                    <span style=" color: #515C6F !important;">
                        Total
                      </span>
                        <br/>

                        <strong class="font-size-xlg text-right" style="width: 120px; color: #515C6F !important;">
                          {{ total | currency }}
                        </strong>
                      </div>
                    </template>-->
        </v-app-bar>
      </v-touch>


      <div  id="list-items" class="pa-0" :class="[$vuetify.theme.dark? 'dark-bg' : '']">

        <v-expansion-panels v-if="this.is_up" mandatory accordion hover tile>
          <v-expansion-panel v-if="Array.isArray(this.openItems) && this.openItems.length > 0">
            <v-expansion-panel-header>
              {{ capitalize($t('erp.lang_ToOrder')) }}

              <v-spacer/>
              <span class="pr-3 mx-auto text-right">{{ parseFloat(totalOpenItems).toFixed(2)| currency }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <mobile-open-item :colors="this.colors" :open-items="this.openItems"
                                :pos-type="this.posType" class="pa-0 px-0 mx-0 ma-0"/>
            </v-expansion-panel-content>
          </v-expansion-panel>


          <v-expansion-panel v-if="Array.isArray(this.orderedItemsOnly) && this.orderedItemsOnly.length > 0">
            <v-expansion-panel-header>
              {{ capitalize($t('erp.lang_ordered')) }}

              <v-spacer/>
              <span class="pr-3 mx-auto text-right">{{
                  (total - parseFloat(totalOpenItems).toFixed(2))| currency
                }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <mobile-ordered-items :colors="this.colors"
                                    :ordered-items-only="this.orderedItemsOnly"
                                    :ordered-vouchers="this.orderedVouchers" :pos-type="this.posType"
                                    class="pa-0 px-0 mx-0 ma-0"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


      </div>

    </div>

    <!-- PLU dialog -->
    <v-dialog max-width="600" v-model="plu_dialog">
      <v-card>
        <v-card-text class="pt-3">
          <v-text-field autofocus hide-details append-icon="mdi-arrow-right" dense
                        @blur="$root.$emit('barcodeScanner.unpause')"
                        @focus="$root.$emit('barcodeScanner.pause')"
                        v-model="EanInput" @click:append="searchEAN" @keyup.enter="searchEAN" outlined
                        placeholder="PLU">

          </v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <BookItem ref="bookItem" :item="item" :itemgroup="itemgroup" :pos-type="posType"
              @itemBooked="itemBooked"></BookItem>
  </div>
</template>

<script>


import {mapGetters, mapState} from "vuex";
import MobileItemGroup from "@/components/pos/mobile/wedgets/MobileItemGroup";
import MobileItem from "@/components/pos/mobile/wedgets/MobileItem";
import ButtonsStorno from "@/components/pos/buttons/ButtonsStorno";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import BookItem from "../../../../mixins/pos/BookItem";
import {capitalize} from 'lodash/string'


import {library} from '@fortawesome/fontawesome-svg-core';

import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faChevronLeft,
  faExchange,
  faMinus,
  faPlus,
  faShoppingBag,
  faShoppingCart,
  faTrashAlt
} from '@fortawesome/pro-light-svg-icons'

import {SwipeList, SwipeOut} from 'vue-swipe-actions';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import InvoiceOpenItem from "@/components/pos/invoice/InvoiceOpenItem";
import InvoiceOrderedItem from "@/components/pos/invoice/InvoiceOrderedItem";
import MobileInvoiceOpenItem from "@/components/pos/mobile/wedgets/MobileInvoiceOpenItem";
import FooterButtonOrder from "@/components/pos/FooterButtonOrder";
import MobileInvoiceOrderedItem from "@/components/pos/mobile/wedgets/MobileInvoiceOrderedItem";
import MobileOpenItem from "@/components/pos/mobile/wedgets/MobileOpenItem";
import MobileOrderedItems from "@/components/pos/mobile/wedgets/MobileOrderedItems";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";


library.add(
    faChevronLeft, faAngleDoubleUp, faAngleDoubleDown, faShoppingBag, faShoppingCart, faExchange, faPlus, faMinus, faTrashAlt
);


export default {
  name: "MobileGrid",

  components: {
    MobileOrderedItems,
    MobileOpenItem,
    MobileInvoiceOrderedItem,
    FooterButtonOrder,
    MobileInvoiceOpenItem,
    InvoiceOrderedItem,
    InvoiceOpenItem,
    MobileItem,
    MobileItemGroup,
    'font-awesome-icon': FontAwesomeIcon,
    SwipeList,
    SwipeOut,
    ButtonsStorno,
    BookItem
  },

  props: {
    colors: {
      type: Object,
      require: true
    },
    isVisible: {
      type: Object,
      require: true
    },
    posType: String,
    responsive: String,
  },

  data() {
    return {
      itemgroup: null,
      item: null,
      EanInput: '',
      plu_dialog: false,
      is_up: false,
      mounted: false,
      limit: {
        page: 1,
        length: 12
      },
      open_total: 0.00,
      order_type: 0,
      refPosGridHeight: 0,
      refPosGridWidth: 0,
      selectedGridItem1: null,
      selectedGridItem2: null,
      gridItems: [],
      indexes: [],
      loadingOrder: false,
      isConnectionError: false,
      loading:false,
      isEmptyWareGroup:false,
    }
  },
  created() {
    this.calc_open_total();
  },
  destroyed() {
    Events.$off('createOrder');
    window.removeEventListener('orientationchange', this.calculateGridWidthHeight);
    window.removeEventListener('resize', this.calculateGridWidthHeight);

    this.$root.$off("pos." + this.posType + ".paginateDown", this.paginateDown);
    this.$root.$off("pos." + this.posType + ".paginateUp", this.paginateUp);
    this.$root.$off("updateTableOrders");

  },
  mounted() {

    this.calculateGridWidthHeight();

    window.addEventListener("resize", this.calculateGridWidthHeight);

    window.addEventListener("orientationchange", this.calculateGridWidthHeight);

    this.$root.$on("pos." + this.posType + ".paginateDown", this.paginateDown);

    this.$root.$on("pos." + this.posType + ".paginateUp", this.paginateUp);

    this.$root.$on("updateTableOrders", () => {
      this.getTableOrders()

    })

    this.getGridItems();
    this.getTableOrders();

    this.$socket.emit("customerdisplay.updateItems", {
      cashierID: this.api.auth.cashierID,
      items: this.customerDisplayItems
    });

    this.$store.dispatch('pos/setCourse',{course:null});
  },
  watch: {
    gastroOpenItems(val) {
      val && this.calc_open_total();
    },
    total(val) {
      val && this.calc_open_total();
    },
    order_type(val) {
      if (val === 0) {
        this.$store.dispatch("pos/gastro/selectInvoiceOrderedItem", this.pos.gastro.selectedOrderedItem);
      } else if (val === 1)
        this.$store.dispatch("pos/gastro/selectInvoiceOpenItem", this.pos.gastro.selectedOpenItem);

    },
    is_up(val) {
      this.order_type = 1;
      this.$nextTick(() => {
        this.order_type = 0;
        this.$forceUpdate();
      })
    }
  },
  computed: {
    ...mapState([
      'settings',
      'deposit',
      'posLayoutTemplates',
      'api',
      'app',
      'pos',
      'tse'
    ]),
    ...mapGetters('pos/gastro', {
      gastroOpenItems: 'openItems',
      gastroOrderedItems: 'orderedItems',
      gastroTableName: 'tableName',
      gastroTotalPrice: 'totalPrice',
      retailTotalPrice: 'totalPrice',
      gastroGoodsValue: 'goodsValue',
      canOrder: 'canOrder',
    }),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient',
    }),
    showOrderButton() {
      if (this.posType !== "gastro") {
        return false;
      }

      if (this.pos.gastro.table === null) {
        return true;
      }

      if (this.pos.gastro.table.name !== 0) {
        if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
          if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
            return true;
          }
        }
      }
      return false;
    },
    total() {
      if (this.posType === "gastro") {
        return this.gastroTotalPrice;
      }

      if (this.posType === "retail") {
        return this.retailTotalPrice;
      }
      return 0.00;
    },
    totalOpenItems() {
      let total = 0

      total = Math.abs(this.gastroTotalPrice - this.gastroGoodsValue);

      return total;
    },
    openItems() {
      if (this.posType === "gastro") {
        return this.gastroOpenItems;
      }

      return [];
    },
    orderedItems() {
      if (this.posType === "retail") {
        return this.pos.retail.orderedItems;
      }

      if (this.posType === "gastro") {
        return this.gastroOrderedItems;
      }

      return [];
    },
    orderedVouchers() {
      return this.orderedItems.filter((orderedItem) => {
        if (orderedItem.ean.includes("V$") || orderedItem.ean.includes("GF$") || orderedItem.ean.includes("ZMYLE$")) {
          return true;
        }
      });
    },
    orderedItemsOnly() {
      return this.orderedItems.filter((orderedItem) => {
        if (!orderedItem.ean.includes("V$") && !orderedItem.ean.includes("GF$") && !orderedItem.ean.includes("ZMYLE$") && !orderedItem.ean.includes("MYWORLD$")) {
          return true;
        }
      });
    },
    gridWidth() {
      /*
      if (this.refPosGridWidth < 960) {
          return 4;
      } else {
          return 5;
      }
      */

      if (this.posLayoutTemplate !== null)
        return this.posLayoutTemplate.gridWidth;

      return 5;
    },
    gridHeight() {
      /*
      if (this.refPosGridHeight < 500) {
          return 3;
      } else {
          return 4;
      }
      */

      if (this.posLayoutTemplate !== null)
        return this.posLayoutTemplate.gridHeight;

      return 4;
    },
    gridItemWidth() {
      return ((this.refPosGridWidth * 0.98) / this.gridWidth) + "px";
    },
    gridItemWidthProp() {
      return ((this.refPosGridWidth * 0.98) / this.gridWidth);
    },
    gridItemHeight() {
      return ((this.refPosGridHeight * 0.98) / this.gridHeight) + "px";
    },
    gridItemHeightProp() {
      return ((this.refPosGridHeight * 0.98) / this.gridHeight);
    },
    currentGridLevel() {
      if (this.selectedGridItem1 === null) {
        return 1;
      }

      if (this.selectedGridItem2 === null) {
        return 2;
      }

      return 3;

    },
    selectedGridItem() {
      if (this.selectedGridItem1 !== null) {
        if (this.selectedGridItem2 !== null) {
          return this.selectedGridItem2;
        }

        return this.selectedGridItem1;
      }

      return null;
    },
    posLayoutTemplate() {
      let posLayoutTemplate = null;

      if (this.settings.settings.hasOwnProperty("posRetailLayoutTemplate") || this.settings.settings.hasOwnProperty("posGastroLayoutTemplate")) {
        //FIND TEMPLATE
        let templateID = 0;
        if (this.posType === "retail") {
          templateID = parseInt(this.settings.settings.posRetailLayoutTemplate);
        } else if (this.posType === "gastro") {
          templateID = parseInt(this.settings.settings.posGastroLayoutTemplate);
        }

        if (templateID > 0) {
          let template = this.posLayoutTemplates.templates.find((template) => {
            if (template.id === templateID) {
              return true;
            }
          });

          if (template) {
            posLayoutTemplate = template;
          }
        }
      }

      return posLayoutTemplate;
    },
    posLayoutTemplateAssignments() {
      let posLayoutTemplate = null;

      if (this.posLayoutTemplate !== null)
        posLayoutTemplate = this.posLayoutTemplate.JSON;

      //CHECK IF WE HAVE ASSIGNMENTS
      if (this.selectedGridItem !== null) {
        if (this.selectedGridItem.hasOwnProperty("template")) {
          if (this.selectedGridItem.template.assignments.length === 0) {
            //CHECK IF WE ARE IN WAREGROUP
            if (this.selectedGridItem.type === "waregroup") {
              // --- AUTO FILL UP ---
              // WE ARE IN WAREGROUP BUT WAREGROUP IS EMPY
              // -> SET posLayoutTemplate as "null" so later we fill up the waregroup
              posLayoutTemplate = null;
            } else {
              posLayoutTemplate = {};
            }
          } else {
            // CHECK IF ASSIGNMENTS HAS ONLY ONE ELEMENT AND ITS NULL
            if(this.selectedGridItem.template.assignments.length === 1&&this.selectedGridItem.template.assignments[0]===null){
              //CHECK IF WE ARE IN WAREGROUP
              if (this.selectedGridItem.type === "waregroup") {
                // --- AUTO FILL UP ---
                // WE ARE IN WAREGROUP BUT WAREGROUP IS EMPY
                // -> SET posLayoutTemplate as "null" so later we fill up the waregroup
                posLayoutTemplate = null;
              } else {
                posLayoutTemplate = {};
              }
            }else{
              posLayoutTemplate = this.selectedGridItem.template.assignments;
            }
          }
        }
      }

      return posLayoutTemplate;
    },
    orderType() {
      return this.order_type;
    },
    isServerSideRendering(){
      // @TODO => NEEDS OPTIMIZATION TEMPORARY
      // USING WHEN USING API FOR POS ACTIONS IS ENABLED WE AUTOMATICALLY ENABLE THE SERVERSIDE RENDERING OF THE POS GRID
      return Number(this.$store.getters['settings/getSettingValue']('layoutSettings_useServerSideRendering')) === 1 || this.app.useAPIForPOSActions;
    }
  },

  sockets: {
    userConnected: function (payload) {
      if (payload.cashierID === this.api.auth.cashierID) {
        this.$socket.emit("customerdisplay.initBookedItems", {
          cashierID: this.api.auth.cashierID,
          items: this.customerDisplayItems
        });
      }
    }
  },

  methods: {
    capitalize,
    paginateUp() {
      this.limit.page--;
      this.getGridItems();
    },

    paginateDown() {
      this.limit.page++;
      this.getGridItems();
    },
    calculateGridWidthHeight() {
      this.refPosGridHeight = document.documentElement.clientHeight - 140;
      this.refPosGridWidth = this.getGridOffsetWidth();
    },
    itemBooked() {
      //CLEAR DATA
      this.EanInput = "";
      this.itemgroup = null;
      this.item = null;
      this.plu_dialog = false;
    },
    searchEAN() {
      this.$refs.bookItem.selectItemEAN(this.EanInput);

      //RESET INPUT FIELD
      this.EanInput = "";
    },
    async getGridItems() {
      try {
        let availableGridItems = [];

        //DEFAULT: USE ALL ITEMGROUPS
        //CHECK IF WE HAVE TEMPLATE OR NOT
        if (this.posLayoutTemplateAssignments === null && (this.isEmptyWareGroup||this.posLayoutTemplate===null || !this.isServerSideRendering)) {
          if (this.selectedGridItem !== null) {
            // availableGridItems = this.itemsByItemgroups[this.selectedGridItem.gridItem.id];

            let sortFunction = "items/paginateActiveItemsByItemgroupSortByName";

            if(this.settings.settings.layoutSettings_itemgroupsSorting == "asc_alpha") {
              sortFunction = "items/paginateActiveItemsByItemgroupSortByNameAsc";
            } else if (this.settings.settings.layoutSettings_itemgroupsSorting == "desc_alpha") {
              sortFunction = "items/paginateActiveItemsByItemgroupSortByNameDesc";
            } else if (this.settings.settings.layoutSettings_itemgroupsSorting == "asc_price") {
              sortFunction = "items/paginateActiveItemsByItemgroupSortByPriceAsc";
            } else if (this.settings.settings.layoutSettings_itemgroupsSorting == "desc_price") {
              sortFunction = "items/paginateActiveItemsByItemgroupSortByPriceDesc";
            }

            let items = await this.$store.dispatch(sortFunction, {
              itemgroupId: this.selectedGridItem.gridItem.id,
              limit: -1,
              page: 1
            });

            items.forEach((item) => {
              // excluding variations from autofill
              if (item.isVariation) {
                return;
              }
              availableGridItems.push({
                type: "item",
                gridItem: {
                  id: item.id,
                  name: item.name,
                  faIcon: item.faIcon,
                  backgroundColor: item.backgroundColor,
                  ean: item.ean,
                  sellPrice: item.sellPrice,
                  variationSize: item.variationSize,
                  variationColor: item.variationColor,
                  productImage: item.productImage
                }
              });
            });

            this.gridItems = availableGridItems;
            return;
          } else {
            //SHOW WAREGROUPS
            let itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");

            itemgroups.forEach((itemgroup) => {
              availableGridItems.push({
                type: "waregroup",
                gridItem: {
                  id: itemgroup.id,
                  name: itemgroup.name,
                  faIcon: itemgroup.faIcon,
                  backgroundColor: itemgroup.backgroundColor
                }
              });
            });
          }

          // availableGridItems = _.orderBy(availableGridItems, 'gridItem.name');
        } else {
          if(this.isServerSideRendering){
            if(this.loading)
              return;

            this.loading=true;
            if(!this.isEmptyWareGroup) {
              this.isEmptyWareGroup = false;
              await this.getServerSideLayout().then(res => {
                if (res.data.success) {
                  availableGridItems = res.data.grid;
                  if (this.selectedGridItem !== null && this.selectedGridItem.type === 'waregroup' && Array.isArray(availableGridItems)) {
                    this.isEmptyWareGroup = true;
                    this.getGridItems();
                  }
                } else {
                  this.$swal({
                    title: this.$t('generic.lang_errorOccurred'),
                    text: this.$t('generic.lang_failedLoadingLayoutData'),
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_retry'),
                    icon: "warning",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                      this.getGridItems();
                    },
                    allowOutsideClick: () => !this.$swal.isLoading(),
                  });
                }
              }).catch(err => {
                console.log(err)
                this.$swal({
                  title: this.$t('generic.lang_errorOccurred'),
                  text: this.$t('generic.lang_failedLoadingLayoutData'),
                  cancelButtonText: this.$t('generic.lang_cancel'),
                  confirmButtonText: this.$t('generic.lang_retry'),
                  icon: "warning",
                  showLoaderOnConfirm: true,
                  preConfirm: () => {
                    this.getGridItems();
                  },
                  allowOutsideClick: () => !this.$swal.isLoading(),
                });
              }).finally(() => {
                this.loading = false;
              })
            }
          }else{
            //GET LAST ITEM OF OBJECT(ASSIGNMENTS)
            let lastPosition = Object.keys(this.posLayoutTemplateAssignments)[Object.keys(this.posLayoutTemplateAssignments).length - 1];

            for (let position = 1; position <= lastPosition; position++) {
              if (!this.posLayoutTemplateAssignments.hasOwnProperty(position)) {
                //EMPTY WAREGROUP
                availableGridItems.push({
                  type: "empty_placeholder"
                });
              } else {
                let assignment = this.posLayoutTemplateAssignments[position];

                // CHECK IF ASSIGNMENT IS NULL
                if (assignment === null) {
                  availableGridItems.push({
                    type: "empty_placeholder"
                  });

                  continue;
                }

                if (assignment.type === "waregroup") {
                  //FIND ORIGIN ITEMGROUP
                  let itemgroupFind = await this.$store.dispatch("itemgroups/getItemgroupByID", assignment.id);

                  if (itemgroupFind) {
                    if (!itemgroupFind.show) {
                      availableGridItems.push({
                        type: "empty_placeholder"
                      });
                    } else {
                      let itemgroup = Object.assign({}, itemgroupFind);

                      if (assignment.color !== null) {
                        itemgroup.backgroundColor = assignment.color;
                      }

                      if (assignment.icon != null) {
                        itemgroup.faIcon = assignment.icon;
                      }

                      //PUSH ITEMGROUP
                      availableGridItems.push({
                        type: "waregroup",
                        gridItem: {
                          id: itemgroup.id,
                          name: itemgroup.name,
                          faIcon: itemgroup.faIcon,
                          backgroundColor: itemgroup.backgroundColor
                        },
                        template: {
                          level: assignment.level,
                          assignments: assignment.assignments,
                          position: assignment.position
                        }
                      });
                    }
                  } else {
                    availableGridItems.push({
                      type: "empty_placeholder"
                    });
                  }
                }

                if (assignment.type === "waregroup_virtual") {
                  //PUSH ITEMGROUP
                  availableGridItems.push({
                    type: "waregroup_virtual",
                    gridItem: {
                      name: assignment.name,
                      faIcon: (assignment.icon !== null ? assignment.icon : ""),
                      backgroundColor: (assignment.color !== null ? assignment.color : ""),
                    },
                    template: {
                      level: assignment.level,
                      assignments: assignment.assignments,
                      position: assignment.position
                    }
                  });
                }

                if (assignment.type === "item") {
                  //FIND ORIGIN ITEM
                  let itemToFind = await this.$store.dispatch("items/getItemByID", assignment.id);

                  if (itemToFind) {
                    if (itemToFind.active === 0) {
                      availableGridItems.push({
                        type: "empty_placeholder"
                      });
                    } else {
                      let item = Object.assign({}, itemToFind);

                      if (assignment.color !== null) {
                        item.backgroundColor = assignment.color;
                      }

                      if (assignment.icon != null) {
                        item.faIcon = assignment.icon;
                      }

                      //PUSH
                      availableGridItems.push({
                        type: "item",
                        gridItem: {
                          id: item.id,
                          name: item.name,
                          faIcon: item.faIcon,
                          backgroundColor: item.backgroundColor,
                          ean: item.ean,
                          sellPrice: item.sellPrice,
                          variationSize: item.variationSize,
                          variationColor: item.variationColor,
                          productImage: item.productImage
                        },
                        template: {
                          level: assignment.level,
                          assignments: assignment.assignments,
                          position: assignment.position
                        }
                      });
                    }
                  } else {
                    availableGridItems.push({
                      type: "empty_placeholder"
                    });
                  }
                }
              }
            }
          }

        }

        // NOW PAGINATE
        let counter = 0;
        let counter2 = 1;
        let gridItems = [];
        let length = this.gridWidth * this.gridHeight;

        //REMOVE ONE GRID ITEM, FOR BACK BUTTON
        if (this.currentGridLevel > 1) {
          length--;
        }
        if(this.isServerSideRendering || this.posLayoutTemplate){
          gridItems = availableGridItems;
        }else if(this.posLayoutTemplateAssignments===null){
          if (parseInt(this.responsive) === 0) {
            availableGridItems.forEach((gridItem) => {
              if ((counter >= ((this.limit.page - 1) * length)) && (counter2 <= length)) {
                gridItems.push(gridItem);

                counter2++;
              }

              counter++;
            });
          } else {
            availableGridItems.forEach((gridItem) => {
              gridItems.push(gridItem);
            });
          }
        }

        this.gridItems = gridItems;
      } catch (e) {
        console.log(e);
      }
    },
    async getServerSideLayout(){
      return this.axios.post(ENDPOINTS.SETTINGS.POSLAYOUT.TEMPLATE.RENDER,{
        templId:this.posLayoutTemplate.id,
        pageId:0,
        position:this.currentGridLevel===1 || this.indexes,
        mobile:1,
      })
    },
    depositGroup(item) {
      if (item.depositgroupID === 0)
        return null;

      //FIND DEPOSIT GROUP
      let depositGroup = this.deposit.deposits.find((depositGroup) => {
        return depositGroup.id === item.depositgroupID;
      });

      if (!depositGroup)
        return null;

      return depositGroup;
    },

    getTableOrders() {
      this.loadingOrder = true;
      this.axios.post(ENDPOINTS.POS.GASTRO.ORDERS.GET, {
        tableName: this.pos.gastro.table.name
      }).then((res) => {
        if (res.data.success) {
          this.$store.dispatch('pos/gastro/setGastroOrder',res.data);
          this.loadingOrder = false;
        } else {
          this.$store.dispatch('pos/gastro/setGastroOrder',null)
          this.isConnectionError = true;
        }
      }).catch(() => {
        this.$store.dispatch('pos/gastro/setGastroOrder',null)
        this.isConnectionError = true;
      });
    },
    getGastroTableFromAPI() {
      // this.loadingTable = true;
      this.loadingOrder = true;
      this.axios.post(ENDPOINTS.POS.GASTRO.ORDERS.GET, {
        tableName: this.pos.gastro.table.name
      }).then((res) => {
        if (res.data.success) {
          //set the customer if there is one
          if (res.data.customerId) {
            //console.log('before customer')
            this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
              customerID: res.data.customerId,
            }).then((res2) => {
              if (res2.data.success) {
                this.$store.dispatch("pos/setCustomer", {
                  posType: this.posType,
                  customer: res2.data.customer,
                  setCustomerOnly:true,
                })
              }
            }).catch((err) => {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t('generic.lang_errorOccurred') + ' ' + this.$t('generic.lang_whileGettingTheCustomer') + ' : ' + err.message,
              });
            });
          } else {
            this.$store.dispatch("pos/setCustomer", {
              posType: this.posType,
              customer: null,
              setCustomerOnly:true,
            })
          }
          this.$store.dispatch('pos/gastro/setGastroOrder',res.data)
          this.loadingOrder = false;
        } else {
          this.$store.dispatch('pos/gastro/setGastroOrder',null)
          this.isConnectionError = true;
        }
      }).catch(() => {
        this.$store.dispatch('pos/gastro/setGastroOrder',null)
        this.isConnectionError = true;
      });
    },
    redirect(route) {

      if (route === 'tables') {

        this.$emit('redirect', 'home');
      }
    },
    calc_open_total() {
      let prices = this.openItems.map((item) => {
        if (this.itemSellPrice(item) === item.originalSellPrice && item.discount === 0)
          return (this.itemSellPrice(item) * item.amount)
        else if (this.itemSellPrice(item) === item.originalSellPrice && item.discount > 0)
          return ((((100 - item.discount) / 100) * this.itemSellPrice(item)) * item.amount);
        else if (this.itemSellPrice(item) !== item.originalSellPrice && item.discount === 0)
          return (this.itemSellPrice(item) * item.amount)
        else if (this.itemSellPrice(item) !== item.originalSellPrice && item.discount > 0)
          return ((((100 - item.discount) / 100) * this.itemSellPrice(item)) * item.amount);
        else return 0;
      });
      this.open_total = prices.reduce(function (a, b) {
        return a + b;
      }, 0);
    },
    async itemSellPrice(elt) {
      let sellPrice = elt.sellPrice
      //GET ITEM
      let item = await this.$store.dispatch("items/getItemByID", elt.id);

      //CHECK MEALSIZE PRICE
      if (elt.mealSizeID > 0) {
        if (item) {
          //THEN GET MEALSIZE FROM REAL ITEM(VUEX STORE)
          let itemMealsize = item.mealSizes.find((mealsize) => mealsize.id === elt.mealSizeID);

          if (itemMealsize)
            sellPrice = itemMealsize.price;
        }
      }

      //CHECK DEPOSIT
      if (this.depositGroup(elt) !== null) {
        sellPrice += this.depositGroup(elt).priceTotal;
      }

      return sellPrice;
    },
    swipeHandler() {
      if (this.currentGridLevel > 1)
        this.goBack()
    },
    swipe_up() {
      document.getElementById('items').classList.add('items_full_screen')
      document.getElementById('list-items').classList.add('list-items-full_screen')
      document.getElementById('gastro_bottom_nav').classList.add('gastro_bottom_nav')
      document.getElementById('gastro_bottom_nav').style.zIndex = 1000;
      this.is_up = true;
    },
    swipe_down() {
      document.getElementById('items').classList.remove('items_full_screen')
      document.getElementById('list-items').classList.remove('list-items-full_screen')
      this.is_up = false;
    },
    gridKey(index, gridItem) {
      if (gridItem.type === "empty_placeholder")
        return gridItem.type + '_' + index;

      return gridItem.type + '_' + gridItem.gridItem.id + '_' + index;
    },
    getGridOffsetWidth() {
      let documentElement = document.getElementById("posGrid");

      if (documentElement === undefined || documentElement === null)
        return 0;

      return documentElement.offsetWidth;
    },
    selectGridItem(gridItem,index) {
      /*if (gridItem.type !== "item") {
        if (gridItem.hasOwnProperty("template")) {
          if (gridItem.template.level === 1) {
            this.selectedGridItem1 = gridItem;
          }

          if (gridItem.template.level === 2) {
            this.selectedGridItem2 = gridItem;
          }
        } else {
          this.selectedGridItem1 = gridItem;
        }
      }

      this.getGridItems();*/
      if (gridItem.type !== "item") {
        this.limit.page = 1;

        if (gridItem.hasOwnProperty("template")) {
          if (gridItem.template.level === 1) {
            this.selectedGridItem1 = gridItem;
          }

          if (gridItem.template.level === 2) {
            this.selectedGridItem2 = gridItem;
          }
        } else {
          this.selectedGridItem1 = gridItem;
        }
      }

      this.indexes.push(Number(index));
      this.getGridItems();
    },
    goBack() {
      //RESET PAGINATION

      this.indexes.splice(this.indexes.length-1,1);

      this.limit.page = 1;

      if (this.currentGridLevel === 2) {
        this.selectedGridItem1 = null;
      }

      if (this.currentGridLevel === 3) {
        this.selectedGridItem2 = null;
      }
      this.isEmptyWareGroup=false;
      this.getGridItems();
    },
  }
}
</script>


<style>
#list-items .v-tabs-bar.v-item-group > * {
  background-image: linear-gradient(to right, #FF6969, #FF8484) !important;
}

</style>
<style scoped>

.delete_swiper {
  height: 56px !important;
  border-radius: 0 !important;
  width: 100% !important;
}

/**
navigation design
 */
#send_btn {
  position: absolute;
  width: 62px;
  height: 62px;
  border-radius: 4px !important;
  border: 1px solid white;
  top: 4px;
  right: 0px !important;
}


#gastro_bottom_nav {
  background-image: linear-gradient(to right, #FF6969, #FF8484);
}


.gastro_bottom_nav {
  background-image: none;
  background: white !important;
}

#items {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 54px;
  z-index: 200;
  overflow-y: auto;
}

.items_full_screen {
  max-height: calc(100%) !important;
  overflow: hidden;
  /* Set our transitions up. */
  -webkit-transition: max-height 50s;
  -moz-transition: max-height 50s;
  transition: max-height 50s;
  background-color: black;
}

#back-btn {
  position: fixed !important;
  top: calc(50% - 55px);
  left: -38px;
  z-index: 100;
  width: 60px !important;
  height: 60px !important;
  padding-left: 20px !important;
  background-color: white !important;
  border: 1px solid #FF6969;
  font-weight: 800 !important;
}

#list-items {
  width: 100% !important;
  height: auto !important;
  margin: 0;
  padding-top: 73px !important;
  background-color: white;
  display: none;
  user-select: none;
  overflow-y: auto;
}

.list-items-full_screen {
  display: block !important;
  position: fixed;
  top: 0;
  z-index: 0;
  bottom: 54px !important;
  left: 0;
  right: 0;
  border-bottom: 0.5px solid #FF6969;
  padding-bottom: 100px !important;

}

#total_items {
  width: 20px !important;
  min-height: 10px !important;
  border: 1px solid white;
  border-radius: 10px;
  background-color: white;
}

.actived_tab {
  font-weight: bolder;
}

</style>


<style>
.v-list-item--link:before {
  width: 0px !important;
  background: transparent !important;
}

#gastro_bottom_nav .v-badge__badge {
  max-width: 23px !important;
  min-width: 23px !important;
  max-height: 223px !important;
  min-height: 23px !important;
  border-radius: 100px !important;
  padding: 5px 2px 0px 2px !important;
  top: -6px !important;
  left: calc(100% - 20px) !important;
  border: 0.5px solid white !important;
  color: black !important;
}
</style>
