<template>
  <v-container class="pa-0 ma-0 mx-0 transparent" fluid style="z-index: 1000">
    <v-row class="mx-0 ma-0 px-0 pa-0 pt-6" no-gutters>

      <v-col class="bg-white pr-2 pl-2 pt-0" cols="12" style="width: 110% !important;">
        <v-row align="center" no-gutters>
          <v-col cols="4">
            <v-list-item-content>
              <v-list-item-subtitle class="text-muted">
                {{ $t('generic.lang_receipt_gegeben') }}
              </v-list-item-subtitle>
              <v-list-item-title class="font-weight-bolder pt-1 font-size-xlg">
                <span class="text-color">
                  {{ this.invoiceResultData.moneyGiven | currency }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-col>

          <v-col cols="3">
            <v-list-item-content>
              <v-list-item-subtitle class="text-muted">
                {{$t('erp.lang_rest')}}
              </v-list-item-subtitle>
              <v-list-item-title class="font-weight-bolder pt-1 font-size-xlg">
                <span class="text-color">
                  {{ moneyBack | currency }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-col>
          <v-col class="text-right" cols="5">
          <span class="text-muted font-size-xlg font-weight-lighter">
            {{ $t('generic.lang_total') }}
          </span>

            <h3 class="font-weight-bolder mx-auto text-color" width="100%">
              {{ total | currency }}
            </h3>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="pa-6" cols="12">
        <v-card class="rounded" elevation="5" style="border-radius: 15px !important;">
          <v-card-text class="pa-0 pt-2">
            <v-form ref="form" lazy-validation>
              <v-text-field v-model="email" :color="this.colors.primary" :disabled="sendLoader"
                            :label="this.$t('generic.lang_emailAdresse')" :loading="sendLoader"
                            height="50">
                <template v-slot:prepend-inner>
                  <font-awesome-icon
                      :icon="['fal', 'user']"
                      class="text-muted ml-2 mt-1"
                      style="font-size: 28px"
                  />
                </template>

                <template v-slot:append>
                  <font-awesome-icon
                      :icon="['fal', 'envelope-open']"
                      class="text-white mr-2 mt-1 rounded-circle"
                      style="background-color: grey; font-size: 30px; padding: 6px !important;"
                      @click="send"
                  />
                </template>
              </v-text-field>

              <br/>
              <br/>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="text-center" cols="6">
        <button v-if="this.$vuetify.breakpoint.smAndDown"
                class="br-tr btn-transition btn text-muted bg-transparent border rounded-lg pa-3"
                style="height: 90px !important;"
                @click="redirect('home')">
          <i class="btn-icon-wrapper">
            <font-awesome-icon :icon="['fal', 'home']" size="3x"/>
          </i>
          <br/>
          <span>{{ $t('customers.lang_bill_nextCust') }}</span>
        </button>
      </v-col>
      <v-col class="text-center" cols="6">
        <button v-if="this.$vuetify.breakpoint.smAndDown"
                class="br-tr btn-transition btn text-muted bg-transparent border rounded-lg pa-3"
                style="height: 90px !important;"
                @click="reprintInvoice">
          <v-progress-circular v-if="loadingPrinting" indeterminate/>
          <i v-else class="btn-icon-wrapper">
            <font-awesome-icon :icon="['fal', 'print']" size="3x"/>
          </i>
          <br/>
          <span>{{ $t('generic.lang_reprint') }}</span>
        </button>
      </v-col>
      <v-col align="center" class="mt-4" v-if="$store.getters['permissions/checkModule'](62)
      &&Number(settings.settings.enablePaperlessInvoice)===1
      &&Number(settings.settings.displayPaperlessInvoiceQr)===1">
        <qr-code
            :text="'https://'+settings.settings.servicesSubDomainName+'.paperlessinvoice.3pos.de/?i='+invoiceResultData.invoiceUUID"
            :size="150"
        >
        </qr-code>
      </v-col>
      <!--
            <v-col class="pa-2" cols="12">
              <v-row align="center" no-gutters>
                <v-col v-for="(print , i) in this.printer.printers" :key="i" class="text-center pa-1" cols="6">
                  <b-button class="ma-auto text-muted rounded bg-white pa-2 border-0"
                            height="90px" style="max-width: 100% !important; min-width: 100% !important;">
                    <font-awesome-icon
                        :icon="['fal', 'print']"
                        class="btn-icon-wrapper ma-auto btn-icon-lg"
                        size="4x"
                        v-bind:style="{
                      color: colors.primary
                        }"
                    />
                    <br/>
                    <span class="icons_text">
                      {{ print.name }}
                    </span>
                  </b-button>
                </v-col>
              </v-row>
            </v-col>-->
    </v-row>
  </v-container>
</template>

<script>


import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faCreditCard,
  faCreditCardBlank,
  faEnvelopeOpen,
  faEuroSign,
  faHandsHeart,
  faHeart,
  faLineColumns,
  faPiggyBank,
  faPrint,
  faReceipt,
  faUser,
} from '@fortawesome/pro-light-svg-icons'
import {mapGetters, mapState} from "vuex";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import VueQRCodeComponent from 'vue-qrcode-component'
import {printDataFromPrinter} from "../../../../plugins/printing/printerController";

library.add(faPrint, faHeart, faReceipt,
    faCreditCard,
    faCreditCardBlank,
    faHandsHeart,
    faEuroSign,
    faPiggyBank,
    faLineColumns,
    faUser,
    faEnvelopeOpen
)

export default {
  name: "SendInvoice",
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    'qr-code': VueQRCodeComponent
  },
  props: {
    colors: {
      type: Object,
      required: true
    },
    posType: {
      type: String,
      required: true
    },
    invoiceResultData: Object,
    invoiceXMLPrintData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    invoiceItemsOverwrite: {
      type: Array,
      default: () => {
        return [];
      }
    },
    newOrderArray: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data: () => {
    return {
      email: "",
      sendLoader: false,
      loadingPrinting: false,
    }
  },
  methods: {
    redirect(route) {
      this.$emit('redirect', route);
    },
    send() {
      if (!this.$refs.form.validate()) return;
      this.sendLoader = true;

      let data = {};
      data.email = this.email;
      data.invoiceID = this.invoiceResultData.invoiceID;
      data.customer = this.customer;
      data.invoiceUUID = this.invoiceResultData.invoiceUUID;
      this.axios.post(ENDPOINTS.EMAILS.AFTERSALE, data).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });

          //  this.$refs.form.reset();
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.sendLoader = false;
      })
    },
    reprintInvoice() {
      //CHECK IF INVOICE PRINTER EXISTS
      if (!this.invoicePrinter) {
        //this.checkVoucher();
        return;
      }

      if(this.invoiceXMLPrintData && this.invoiceXMLPrintData.length === 0)
        return;

      //ADDING SCALE TEXT STANDARD
      if(this.$store.getters['scale/isEnabled']){
        this.invoiceXMLPrintData.unshift({
          text:(this.$i18n.locale==='de'?' - Kopie - ':' - Copy - '),
        })
        this.invoiceXMLPrintData.unshift({
          align:'center',
        })
        this.invoiceXMLPrintData.unshift({
          fontSize:2,
        })
      }

      printDataFromPrinter(this.invoicePrinter,this.invoiceXMLPrintData).then(()=>{

      }).catch((err)=>{
        this.$swal({
          title: this.$t('generic.lang_errorOccurred'),
          text: this.$t('generic.lang_printReceiptError')+' '+err,
          icon: "warning",
          showLoaderOnConfirm: true,
          confirmButtonText:this.$t("generic.lang_confirm"),
          preConfirm: () => {

          },
          allowOutsideClick: () => !this.$swal.isLoading,
        });
      }).finally(()=>{
        this.loadingPrinting = false;
      })

    },
  },
  computed: {
    ...mapState([
      'pos',
      'api',
      'tse',
      'cashierIDs',
      'settings',
      'posLayoutTemplates',
      'printer',
      'payments',
      'user',
      'deposit'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      retailGoodsValue: 'pos/retail/goodsValue',
      fiscalClient: 'tse/fiscalClient',
      activeUser: 'multiUser/activeUser',
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    customer() {
      if (this.posType === "gastro") {
        return this.pos.gastro.customer;
      }

      if (this.posType === "retail") {
        return this.pos.retail.customer;
      }

      return null;
    },
    moneyBack() {
      let value = this.invoiceResultData.moneyGiven - this.total;
      if (value < 0) {
        return 0;
      }

      return value;
    },
    invoiceItems() {
      let invoiceItems = [];

      if (this.posType === "retail") {
        invoiceItems = this.pos.retail.orderedItems;
      }

      if (this.posType === "gastro") {
        //USE OVERWRITE ARRAY -> WHEN WE SPLIT PAY TABLE IN GASTRO POS, OUR INVOICEITEMS ARE PASSING WITH invoiceItemsOverwrite(PROP)
        if (this.invoiceItemsOverwrite.length > 0) {
          invoiceItems = this.invoiceItemsOverwrite;
        } else {
          if (!this.pos.gastro.hasOwnProperty("table"))
            return invoiceItems;

          if (!this.pos.gastro.table.hasOwnProperty("name"))
            return invoiceItems;

          if (!this.pos.gastro.hasOwnProperty("party"))
            return invoiceItems;

          if (!this.pos.gastro.party)
            return invoiceItems;

          if (!this.pos.gastro.party.hasOwnProperty("name"))
            return invoiceItems;

          if (this.pos.gastro.table.name !== 0) {
            if (this.pos.gastro.orderedItems.hasOwnProperty(this.pos.gastro.party.name)) {
              if (this.pos.gastro.orderedItems[this.pos.gastro.party.name].length > 0) {
                invoiceItems = this.pos.gastro.orderedItems[this.pos.gastro.party.name];
              }
            }
          } else {
            if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
              if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
                invoiceItems = this.pos.gastro.openItems[this.pos.gastro.party.name];
              }
            }
          }
        }
      }

      return invoiceItems.map((invoiceItem) => {
        //CONVERT VOIDED
        // invoiceItem.isVoid = invoiceItem.isVoid ? 1 : 0;

        return invoiceItem;
      });
    },
    total() {
      let total = 0;

      this.invoiceItems.forEach((item) => {
        if (item.isVoid && item.amount === 0) {
          return;
        }

        //CHECK IF WEIGHT IS SET
        let amount = item.amount;
        if (item.weight)
          amount = item.weight;

        //CHECK IF DEPOSIT
        if (item.depositgroupID > 0) {
          let deposit = this.deposit.deposits.find((depositGroup) => {
            return depositGroup.id === item.depositgroupID;
          });
          if (Object.keys(deposit).length > 0)
            total += deposit.priceTotal;
        }

        total += amount * parseFloat((Math.round(((item.sellPrice * ((100 - item.discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
        //total += amount * (((100 - item.discount) / 100) * item.sellPrice);
      });

      return total;
    }
  },
  created() {
    if (this.customer)
      this.email = this.customer.email;
  }
}
</script>

<style scoped>

</style>


<style scoped>
.text-color {
  color: #515C6F !important;
}

.icons_text {
  font-size: 10px !important;
}
</style>