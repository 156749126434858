<template>

  <v-card
      :color="backgroundColor"
      class="mx-auto pa-1"
      elevation="1"
      max-width="220px"
  >
    <v-row
        align="center"
        class="row mx-0 white--text"
        style="height: 70px;"
        width="100%"
    >
      <font-awesome-icon v-if="isFaIcon"
                         :icon="['fal', itemgroup.faIcon]"
                         :style="{color: (checkColorLightOrDark(backgroundColor) === 'light' ? 'black' : 'white')}"
                         class="itemgroupIcon mx-auto"/>
      <h1 v-else :class="(checkColorLightOrDark(backgroundColor) === 'light' ? 'text-muted' : 'text-light')"
          class="mx-auto">
        {{ itemgroup.name.substring(0, 1) }}
      </h1>
    </v-row>

    <v-card-subtitle :style="{
          color: (checkColorLightOrDark(backgroundColor) === 'light' ? 'black' : 'white'),
          'font-size': (settings.settings.layoutSettings_mobileFontSize > 0) ? `${settings.settings.layoutSettings_mobileFontSize}px !important` : `12px !important`
        }"
                     class="pa-0 mx-0 pt-2 itemgroupName">
      {{ (getItemGroupNameLength > 0) ? itemgroup.name.substring(0, getItemGroupNameLength) : itemgroup.name }}
    </v-card-subtitle>

  </v-card>
  <!-- <v-card v-ripple="{class: 'primary--text'}" :color="backgroundColor" class="pa-0" elevation="1" height="100%"
           width="100%">
     <v-card-text class="pa-0">
       <v-container>
         <v-row>
           <v-col class="text-center" cols="12">

             <p>
               <font-awesome-icon v-if="isFaIcon"
                                  :icon="['fal', itemgroup.faIcon]"
                                  :style="{color: (checkColorLightOrDark(backgroundColor) === 'light' ? 'black' : 'white')}" class="itemgroupIcon"/>
             </p>
HS
             <p :style="{color: (checkColorLightOrDark(backgroundColor) === 'light' ? 'black' : 'white')}"
                class="itemgroupName">{{ itemgroup.name }}</p>
           </v-col>
         </v-row>
       </v-container>
     </v-card-text>
   </v-card>-->
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core';

import {
  faCheeseburger,
  faCocktail,
  faCroissant,
  faFish,
  faFrenchFries,
  faGifts,
  faHamburger,
  faMeat,
  faPizzaSlice,
  faSalad,
  faShishKebab
} from '@fortawesome/pro-light-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {checkColorLightOrDark} from "@/plugins/checkColorLightOrDark";
import {mapState} from "vuex";

library.add(
    faCocktail,
    faMeat,
    faGifts,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faCroissant,
    faSalad
);

export default {
  name: "MobileItemGroup",

  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    itemgroup: Object
  },
  data() {
    return {
      defaultLength: 11,
    }
  },
  computed: {
    ...mapState([
      'settings'
    ]),
    getItemGroupNameLength() {
      if (Number(this.settings.settings.layoutSettings_itemNameLength) === 0) {
        return 0
      } else if (Number(this.settings.settings.layoutSettings_mobileItemNameLength) < this.defaultLength) {
        return this.defaultLength;
      }
      return Number(this.settings.settings.layoutSettings_mobileItemNameLength);
    },
    backgroundColor() {
      if (this.item?.backgroundColor === "" || this.$vuetify.theme.dark) {
        return "#FFFFFF";
      }
      return this.itemgroup.backgroundColor;
    },
    isFaIcon() {
      return this.itemgroup.faIcon !== "";
    }
  },

  methods: {
    checkColorLightOrDark
  }
}
</script>

<style scoped>
.itemgroupIcon {
  font-size: 30px;
}

@media (max-width: 1024px) {
  .itemgroupName {
    font-size: 14px;
    height: 45px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 1025px) {
  .itemgroupName {
    font-size: 19px;
    height: 45px;
    display: flex;
    align-items: center;
  }
}
</style>
