<template>
  <video id="video" height="100%" class="" width="100%"></video>
</template>


<script>

const ZXing = require('@zxing/library');

const codeReader = new ZXing.BrowserMultiFormatReader();

export default {
  name: "Reader",
  props: {
    open: Boolean
  },
  data: () => {
    return {
      selectedDeviceId: '',
      videoInputDevices: ''
    }

  },
  methods: {
    async read() {

      try {

        codeReader.listVideoInputDevices()
            .then((videoInputDevices) => {
              this.selectedDeviceId = videoInputDevices[0].deviceId
              this.videoInputDevices = videoInputDevices;
              //console.log(videoInputDevices)
            }).catch(err => {
         // alert(err.message)
        });

        await codeReader.decodeFromVideoDevice(this.selectedDeviceId, 'video', (result, err) => {
          if (result) {
            ////console.log(result)
            document.getElementById('result').textContent = result.text
          }
          if (err && !(err instanceof ZXing.NotFoundException)) {
            console.error(err)
            document.getElementById('result').textContent = err
          }
        })
      } catch
          (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.read();
  }
}
</script>

<style scoped>

</style>
