<template>
  <v-dialog v-model="dialog" class="mx-0" hide-overlay max-width="600" scrollable @click:outside="closeDialog()">
    <v-card :class="[this.$vuetify.theme.dark? 'dark-bg' : 'bg-white']" class="rounded-lg mx-auto ma-auto" style="border-radius: 25px !important;" width="100%">
      <v-card-text class="pa-0 rounded-lg">
        <div class="grid-menu grid-menu-3col rounded-lg">
          <v-row align="center" class="pa-0 rounded-lg" no-gutters>
            <v-col v-for="course in getCourses" :key="course.id" class="text-center rounded-lg" cols="4">
              <b-button
                  :class="[$vuetify.theme.dark? 'dark-bg' : 'bg-white', 'ma-auto btn-icon-vertical text-muted btn-square btn-transition '+isActiveCourse(course)]"
                  @click="setCourse(course)">
                <font-awesome-icon
                    :class="'btn-icon-wrapper ma-auto btn-icon-md icons mb-2 '+isActiveCourse(course)"
                    :icon="['fal',(course.courseIcon===null||course.courseIcon==='')?'utensils-alt':course.courseIcon]"
                    :style="{color:'#FF6969'}"
                />
                <span class="icons_text">
                {{ course.name }}
                </span>
              </b-button>
            </v-col>

            <v-col class="text-center rounded-lg" cols="4">
              <b-button @click="signalKitchen()"
                        :class="[this.$vuetify.theme.dark? 'dark-bg' : 'bg-white']" class="ma-auto btn-icon-vertical text-muted btn-square btn-transition">
                <div v-if="!loadingPrinting">
                  <font-awesome-icon :style="{color:'#FF6969'}"
                                     :class="'btn-icon-wrapper ma-auto btn-icon-md icons mb-2'"
                                     :icon="['fal','long-arrow-right']"/>

                  <span class="icons_text">
                {{$t('generic.lang_prepareNext')}}
                </span>
                </div>

                <v-progress-circular
                    v-else indeterminate
                />

              </b-button>
            </v-col>

          </v-row>
        </div>
      </v-card-text>
      <v-dialog v-model="errorDialog" persistent width="500">
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="text-center">
                  <p style="font-size: 17px;">{{ errorDialogMessage }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn color="error" v-if="errorDialogShowCancelButton" :disabled="errorDialogLoading" text
                   @click="closeErrorDialog">{{ $t('generic.lang_cancel') }}
            </v-btn>
            <v-spacer/>
            <v-btn color="success" :disabled="errorDialogLoading" :loading="errorDialogLoading"
                   @click="closeErrorDialog">
              {{ errorDialogConfirmButtonText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faBarcode,
  faCheeseburger,
  faCocktail,
  faConciergeBell,
  faCroissant,
  faFish,
  faFrenchFries,
  faGifts,
  faHamburger,
  faHotdog,
  faMeat,
  faPersonCarry,
  faPizzaSlice,
  faSalad,
  faSandwich,
  faShishKebab,
  faSoup,
  faTaco,
  faUtensilsAlt,
} from '@fortawesome/pro-light-svg-icons'
import {ENDPOINTS} from "@/config";
import {printDataFromPrinter} from "../../../../plugins/printing/printerController";
import {geol} from "@/main";

library.add(
    faHotdog,
    faBarcode,
    faCocktail,
    faPersonCarry,
    faMeat,
    faGifts,
    faConciergeBell,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faCroissant,
    faSalad,
    faTaco,
    faSandwich,
    faSoup,
    faUtensilsAlt,
);

export default {
  name: "MobileCourseDialog",
  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    posType: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      dialog: false,
      loadingPrinting: false,
      errorDialog: false,
      errorDialogMessage: "",
      errorDialogShowCancelButton: false,
      errorDialogLoading: false,
      errorDialogConfirmButtonText: ""
    }
  },
  watch: {
    showDialog(val) {
      this.dialog = val;
    }
  },
  computed: {
    ...mapState(['courses', 'pos', 'api']),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      user: 'user/user'
    }),
    invoicePrinter() {

      let defaultPrinter = this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 2) {
            return true;
          }
        }
      });

      let coursePrinter = this.printers.find((printer) => {
        if (this.pos.gastro.selectedCourse && printer.id === this.pos.gastro.selectedCourse.orderbonPrinterID) {
          return true;
        }
      });

      if (coursePrinter) {
        return coursePrinter;
      }else if(defaultPrinter){
        return defaultPrinter;
      }

      return coursePrinter;

    },
    getCourses() {
      return this.courses.courses;
    },
  },
  mounted() {
    // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
    // we re gonna use this work arround for now until we find a better way
    if (this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
        geol.locale = this.$store.getters['settings/getSettingValue']('geoloc_systemcountry');
    }
  },
  methods: {
    showErrorDialog(message, showCancelButton = false, closeAction = null, confirmButtonText = null) {
      this.errorDialogLoading = false;
      this.errorDialogShowCancelButton = false;

      this.errorDialogMessage = message;
      this.errorDialogShowCancelButton = showCancelButton;

      if (closeAction !== null)
        this.errorDialogCloseAction = closeAction;
      else
        this.errorDialogCloseAction = "";

      if (confirmButtonText !== null)
        this.errorDialogConfirmButtonText = confirmButtonText;
      else
        this.errorDialogConfirmButtonText = this.$t('generic.lang_next');

      //OPEN ERROR DIALOG
      this.errorDialog = true;
    },
    closeErrorDialog() {
      this.errorDialog = false;

      if (this.errorDialogCloseAction === "close_only")
        return
    },
    signalKitchen() {

      if (!this.invoicePrinter) {
        this.showErrorDialog(this.$t('generic.lang_printReceiptError'), false, "close_only");
        return;
      }

      this.loadingPrinting = true

      // GET NEXT COURSE
      let course = (this.pos.gastro.selectedCourse === null) ? '' : ':' + this.pos.gastro.selectedCourse.name.toUpperCase()

      // EMIT TO WEBSOCKET SERVER
      this.$socket.emit("kitchenmonitor.nextCourse", {
        course: course,
        table: this.pos.gastro.table.name,
        party: this.pos.gastro.party.name
      });

      // PREPARE PRINT DATA
      let printData = [];

      printData.push({
        feed: 1
      });
      printData.push({
        font: "A"
      });
      printData.push({
        align: "center"
      });

      printData.push({
        fontSize: 2
      });

      printData.push({
        text: geol.t('generic.lang_nextCourse') + course
      });

      let table = (this.pos.gastro.table.name == 0) ? '' : 'TABLE ' + this.pos.gastro.table.name.toString().toUpperCase()
      let party = this.pos.gastro.party.name.toString().toUpperCase()
      printData.push({
        reverse: "on"
      });

      printData.push({
        text: table + '-' + party
      });

      printData.push({
        reverse: "off"
      });

      printData.push({
        feed: 4
      });

      let date = new Date();

      printData.push({
        text: date.getHours() + ':' + date.getMinutes()
      });
      printData.push({
        text: geol.t('generic.lang_seller') + ': ' + this.user.fullname,
      });
      printData.push({
        feed: 4
      });
      printData.push({
        cut: true
      });

      try {

        // EPSON EPOS PRINT
        printDataFromPrinter(this.invoicePrinter,printData).then(()=>{
          this.closeDialog();
        }).catch((err)=>{
          this.showErrorDialog(this.$t('generic.lang_printReceiptError'), false, "close_only");
        }).finally(()=>{
          this.loadingPrinting = false;

        })

      } catch (err) {
        console.log(err);
        this.loadingPrinting = false;
        this.showErrorDialog(this.$t('generic.lang_printErrorNextCourse'), false, "close_only");
      }


    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    isActiveCourse(course) {
      if (this.pos.gastro.selectedCourse !== null) {
        if (this.pos.gastro.selectedCourse.id === course.id) {
          return 'active-course';
        }
      }
      return '';
    },
    setCourse(course) {
      if (this.pos.gastro.selectedCourse !== null && this.pos.gastro.selectedCourse.id === course.id) {
        this.$store.dispatch("pos/setCourse", {
          posType: this.posType,
          course: null,
        }).then(() => {
          this.closeDialog();
        });
      } else {

        this.$store.dispatch("pos/setCourse", {
          posType: this.posType,
          course: course,
        }).then(() => {
          this.closeDialog();
        });
      }
    }
  },
}
</script>

<style scoped>
.active-course {
  color: white !important;
  background-color: #FF6969 !important;
}
</style>
