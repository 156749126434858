var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-badge',{staticClass:"pa-0 px-0 ma-1 mx-0 qt-badge",staticStyle:{"min-width":"100% !important","max-width":"100% !important"},attrs:{"content":_vm.qty + '',"bottom":"","color":"#FF6969"}},[(this.item.productImage && this.item.productImage.length > 7)?_c('button',{staticClass:"pa-0",staticStyle:{"min-width":"100%","max-width":"100% !important"},attrs:{"id":'item_' + this.item.id}},[_c('v-card',{staticClass:"mx-auto pa-1",attrs:{"color":_vm.backgroundColor,"elevation":"1","max-width":"100%","width":"100%"},on:{"click":_vm.selectItem}},[_c('v-card-subtitle',{staticClass:"pa-0 item_name mx-0 mb-4 pt-2 pl-0 text-left",style:({
        color: (_vm.checkColorLightOrDark(_vm.backgroundColor) === 'light' ? 'black' : 'white'),
        'font-size': (_vm.settings.settings.layoutSettings_mobileFontSize > 0) ? `${_vm.settings.settings.layoutSettings_mobileFontSize}px !important` : `12px !important`
      })},[_vm._v(" "+_vm._s((_vm.settings.settings.layoutSettings_mobileItemNameLength > 0) ? _vm.itemName.substring(0, _vm.settings.settings.layoutSettings_mobileItemNameLength) : _vm.itemName)+" ")]),_c('strong',{staticClass:"mx-auto font-weight-bolder pl-0 text-left",class:(_vm.checkColorLightOrDark(_vm.backgroundColor) === 'light' ? 'text-black' : 'text-light'),staticStyle:{"margin-left":"-27px !important"},style:({
        color: _vm.$vuetify.theme.dark? 'black' : 'white'
      })},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.item.sellPrice))+" ")]),_c('BookItem',{ref:"bookItem",attrs:{"pos-type":_vm.posType}})],1)],1):_c('button',{staticClass:"pa-0",staticStyle:{"min-width":"100%","max-width":"100% !important"},attrs:{"id":'item_' + this.item.id}},[_c('v-card',{staticClass:"mx-auto pa-1",attrs:{"color":_vm.backgroundColor,"elevation":"1","max-width":"100%"},on:{"click":_vm.selectItem}},[_c('v-card-subtitle',{staticClass:"pa-0 item_name mx-0 mb-4 pt-2 text-justify pl-0",style:({
        color: (_vm.checkColorLightOrDark(_vm.backgroundColor) === 'light' ? 'black' : 'white'),
        'font-size': (_vm.settings.settings.layoutSettings_mobileFontSize > 0) ? `${_vm.settings.settings.layoutSettings_mobileFontSize}px !important` : `12px !important`
      })},[_vm._v(" "+_vm._s((_vm.settings.settings.layoutSettings_mobileItemNameLength > 0) ? _vm.itemName.substring(0, _vm.settings.settings.layoutSettings_mobileItemNameLength) : _vm.itemName)+" ")]),_c('span',{staticClass:"mx-auto font-weight-bolder pl-0 text-left",class:(_vm.checkColorLightOrDark(_vm.backgroundColor) === 'light' ? 'text-black' : 'text-light'),staticStyle:{"margin-left":"-18px !important"},style:({
        color: _vm.$vuetify.theme.dark? 'black' : 'white'
      })},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.item.sellPrice))+" ")]),_c('BookItem',{ref:"bookItem",attrs:{"pos-type":_vm.posType}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }